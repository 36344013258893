import "./FileSizeAlert.css"; 

const FileSizeAlert = ({ isOpen, onClose }) => {
    if (!isOpen) return null;
    
    return (
      <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
        <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4">
          <div variant="destructive">
            <div className="text-center">
              La taille du fichier ne doit pas dépasser 10 Mo.
            </div>
          </div>
          <div className="mt-4 flex justify-center">
            <button
              onClick={onClose}
              className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700"
            >
              Fermer
            </button>
          </div>
        </div>
      </div>
    );
  };
  
  export default FileSizeAlert;